import { checkResponse } from "../functions/check-response";
const DADATA_TOKEN = "5f750fb32f49e6781e17d074d02604d65f1bec2f";

export async function getCompanies(value: string) {
  return fetch(
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party",
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + DADATA_TOKEN,
      },
      body: JSON.stringify({ query: value }),
    }
  )
    .then((res) =>
      checkResponse(
        res,
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party"
      )
    )
    .then((res) => {
      return res.suggestions;
    });
}

export const getGeo = async (city: string) => {
  return await fetch(
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + DADATA_TOKEN,
      },
      body: JSON.stringify({
        query: city,
        from_bound: { value: "city" },
        to_bound: { value: "city" },
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res);
};

export const getCityDaData = async (ip: string) => {
  let query = { ip };
  const result = await fetch(
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address",
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + DADATA_TOKEN,
      },
      body: JSON.stringify(query),
    }
  )
    .then((res) => res.json())
    .then((res) => res);
  return !!result.location ? result.location.data : false;
};
