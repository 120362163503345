"use client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./notification.scss";

const Notification = () => {
  return <ToastContainer position="bottom-right" stacked />;
};

export default Notification;
