"use client";
import { scrollToTop } from "@/utils/functions/scrollToTop";
import { ArrowIcon } from "../icon";
import styles from "./arrow-top.module.scss";
import { FC, useEffect, useState } from "react";

export const ArrowTop: FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.scrollY > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    isVisible && (
      <button className={styles.btn} onClick={scrollToTop}>
        <ArrowIcon fill={"#FFFFFF"} className={styles.icon} />
      </button>
    )
  );
};