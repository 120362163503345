import {request} from "../functions/request";
import {getGeo} from "./dadata";

interface IgetDeliveryPointsRequest {
  company: string;
  arg: string;
}

export async function getDeliveryPointsRequest(props: IgetDeliveryPointsRequest) {
  const {
    company,
    arg,
  } = props
  if (!company || !arg) return [];
  switch (company) {
    case "cdek": {
      const currentCity = await getGeo(arg);
      return request(
        `/order/delivery/cdek/get_point.php?fias_guid=${currentCity.suggestions[0].data.city_fias_id}`
      );
    }
    case "boxberry": {
      return request(`/order/delivery/boxberry/get_point.php?city_name=${arg}`);
    }
    case "pochta": {
      return request(`/order/delivery/postal/get_point.php?city_name=${arg}`);
    }
  }
}

export async function createOrderRequest(data: any) {
  return request(`/order/checkout/huein.php`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export async function checkOrderRequest(orderId: string) {
  return request(`/order/checkout/checkpay.php?orderId=${orderId}`);
}
